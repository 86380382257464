import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ProceedButton from '../components/ProceedButton';
import useRedirectToErrorPage from './errors/hooks/redirectToErrorPage';
import { useSearchParams } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";

export default function InstructionScreen() {
  const clientCode = useOutletContext();
  const [errorMessage, setErrorMessage] = useState();
  const [clientName, setClientName] = useState("");
  const redirectToErrorPage = useRedirectToErrorPage(errorMessage);
  const [searchParams] = useSearchParams();


  useEffect(() => {

    let body = JSON.stringify(
      {
        "key": process.env.REACT_APP_API_KEY,
        "dati": 2,
        "codice_utente": clientCode
      }
    )

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`, body).then((response) => {
      if (response.data["status"] !== "success") {
        setErrorMessage(`Si è verificato un errore. Rieffettuare la procedura con un nuovo link. Se l'errore persiste contattare l'assistenza.${response.data["descrizione"]}`)
      } else {
        setClientName(response.data["data"]["nome"]);
      }
    })
  }, [redirectToErrorPage, searchParams, clientCode])


  return (
    <>
      <div className='flex flex-row justify-center'>
        <div className='flex flex-col w-8/12 lg:w-4/12 items-center'>
          <h1 className="text-2xl font-medium">
            {`Salve ${clientName}`}
          </h1>
          <div className='h-8'></div>
          <p className='text-xl'>
            Al fine di poter proseguire con la firma dei contratti digitali le chiederemo i seguenti dati:
            <br></br> <br></br>
            <ol className='list-disc text-orange-600 font-bold'>
              <li> Nome </li>
              <li> Cognome </li>
              <li> Codice Fiscale </li>
              <li> Residenza </li>
              <li> Data di Nascita </li>
            </ol>
            <br></br>
            Le sarà richiesto inoltre anche il caricamente di due foto, fronte-retro, del suo <span className='text-orange-600 font-bold'>documento d’identità</span> e <span className='text-orange-600 font-bold'>codice fiscale</span>.
          </p>
          <div className='h-12' />
          <ProceedButton route={'/primo-step'} />
        </div>
      </div>

    </>
  );
}


