import { useLocation } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react';
import { GeneralButton } from '../components/GeneralButton';
import useRedirectToErrorPage from './errors/hooks/redirectToErrorPage';
import axios from "axios";
import { LoadingComponent } from '../components/LoadingComponent';

export const ContractSigningPanel = () => {
    const location = useLocation();
    const email = useRef("");
    const [text, setText] = useState(1);
    const [contractUrl, setContractUrl] = useState("");
    const [isPollingActive, setIsPollingActive] = useState(false);
    const [isProcessTerminated, setIsProcessTerminated] = useState(false);
    const [header, setHeader] = useState("Hai quasi terminato...");
    const [errorMessage, setErrorMessage] = useState();
    const redirectToErrorPage = useRedirectToErrorPage(errorMessage);
    const tipocontratto = useRef("");


    useEffect(() => {
        api();
    }, [])

    function api(string = "Okay firma") {
        email.current = location.state.email;
        let body = JSON.stringify(
            {
                "key": process.env.REACT_APP_API_KEY,
                "dati": 9,
                "email": email.current,
            }
        );
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`, body).then((response) => {

            if (response.data["status"] !== "success") {
                setErrorMessage("Si è verificato un errore. Non è possibile effettuare la procedura, contattare l'assistenza.")
            }
            if (response.data["data"]["contratto_firmato"] === "no") {
                tipocontratto.current = "contratto_firmato";
                setText(`${string} il primo contratto`);
                setContractUrl(response.data["data"]["url_firma_contratto"]);
            } else if (response.data["data"]["delega_firmato"] === "no") {
                tipocontratto.current = "delega_firmato";
                setText(`${string} la delega`);
                setContractUrl(response.data["data"]["url_firma_delega"]);
            } else if (response.data["data"]["proforma_firmato"] === "no") {
                tipocontratto.current = "proforma_firmato";
                setText(`${string} proforma`);
                setContractUrl(response.data["data"]["url_firma_proforma"]);
            } else {
                setText("Perfetto, hai terminato il processo di firma, puoi chiudere questa pagina.");
                setHeader("Firma Completata");
                setIsProcessTerminated(true);
            }
        });
    }

    useEffect(() => {
        if (isPollingActive === true) {
            const intervalId = setInterval(() => {
                let body = JSON.stringify(
                    {
                        "key": process.env.REACT_APP_API_KEY,
                        "dati": 9,
                        "email": email.current,
                    }
                );
                axios.post(`${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`, body).then((response) => {

                    if (response.data["status"] !== "success") {
                        setErrorMessage("Si è verificato un errore. Non è possibile effettuare la procedura, contattare l'assistenza.")
                    }
                    if (response.data["data"][tipocontratto.current] === "si") {
                        setIsPollingActive(false);
                        api("Perfetto, adesso firma");
                    }
                });
            }, 5000);
            return () => clearInterval(intervalId);
        }
    }, [isPollingActive]);

    function onButtonClick() {
        setText("Perfetto! Siamo in attesa della tua firma...");
        window.open(contractUrl, "_blank", "noreferrer");
        setIsPollingActive(true);
    }

    return (
        <div className="flex flex-col items-center">
            <div className="w-10/12 lg:w-4/12 flex flex-col items-center">
                <h1 className="text-2xl font-bold text-green-600">{header}</h1>
                <div className="h-4" />
                <DynamicTextComponent text={text} />
                <div className="h-4" />
                {isPollingActive === true ?
                    <LoadingComponent /> :
                    isProcessTerminated === false ?
                        <GeneralButton buttonText={"FIRMA"} onClick={onButtonClick} /> : null}
            </div>
        </div>
    )
}

function DynamicTextComponent({ text }) {
    return (
        <div className='h-40'>
            <p className="text-align-center">
                {text}
            </p>
        </div>
    )
}
