import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import useRedirectToErrorPage from '../errors/hooks/redirectToErrorPage';
import axios from "axios";
import { getClientDataGivenClientCode } from '../../functions/getClientData'

/**
 * Questo step ha due casistiche:
 * 
 *  - Prima casistica: Il cliente ha selezionato, da SecondStepInstruction, "carta d'identità elettronica"
 *    come documento da caricare, in questo caso, siccome è gia presente il codice fiscale dovrà caricare
 *    solamente quel documento fronte retro.
 * 
 *  - Seconda casistica: Il cliente ha selezionato, da SecondStepInstruction, "altri documenti", come documento
 *    da caricare, in questo caso dovrà caricare quel documento più un documento contenente il codice fiscale.
 * 
 */

export default function SecondStepWizard() {
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState();
    const redirectToErrorPage = useRedirectToErrorPage(errorMessage);
    const [myFile, setMyFile] = useState({
        documento_fronte: null,
        documento_retro: null,
        codicefiscale_fronte: null,
        codicefiscale_retro: null
    });

    const email = useRef("");
    const clientCode = useOutletContext();

    // Questa variabile tiene traccia se il caricarsi del documento è sufficiente, 
    // per maggiori informazioni vedi documentazione all'inizio del file.
    const [isDocumentEnough, setIsDocumentEnough] = useState(true);

    const handleInputChange = (event, documento) => {
        let file = event.target.files[0];
        setMyFile(prevState => ({
            ...prevState,
            [documento]: file
        }))
    }

    useEffect(() => {
        if (location.state === null || location.state.key['authorized'] !== true) {
            setErrorMessage("Si è verificato un errore. Rieffettuare la procedura con un nuovo link. Se l'errore persiste contattare l'assistenza.");
        } else {
        
        const fetchData = async () => {
            let response = await getClientDataGivenClientCode({ clientCode });
            if(response.data["status"] !== "success") {
                setErrorMessage("Si è verificato un errore. Codice cliente non valido. Se l'errore persiste contattare l'assistenza.")
            } else {
                email.current = response.data["data"]["email"];
            }
        }

        fetchData();
        setIsDocumentEnough(location.state.isDocumentEnough);
    }
    // eslint-disable-next-line
    }, [location, redirectToErrorPage]);

    return (
        <div className='flex flex-col items-center'>
            <h1 className='text-xl font-semibold text-orange-500'>Carica i tuoi documenti</h1>
            <div className='h-12' />
            <DocumentInputField myFile={myFile} setMyFile={setMyFile} handleInputChange={handleInputChange} />
            {isDocumentEnough === true ? <></> : <CodiceFiscaleInputField myFile={myFile} setMyFile={setMyFile} handleInputChange={handleInputChange} />}

            <SubmitButton filesToUpload={myFile} setErrorMessage={setErrorMessage} email={email} />
        </div>
    )
}

const DocumentInputField = ({ myFile, setMyFile, handleInputChange }) => {
    return (
        <div className='flex flex-col sd:w-3/12 md:2-8/12 lg:w-4/12'>
            <span className={myFile['documento_fronte'] === null ? `text-red-600` : `text-green-600`}> Documento Fronte</span>
            <div className='h-2' />
            <div className='flex items-center justify-center border-2 border-dashed h-28 p-2 flex flex-col justify-center border-orange-500'>
                <input
                    className='w-full'
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    id="documento-fronte"
                    onChange={(event) => handleInputChange(event, "documento_fronte")}
                />
            </div>
            <div className='h-10' />
            <span className={myFile['documento_retro'] === null ? `text-red-600` : `text-green-600`}> Documento Retro</span>
            <div className='h-2' />
            <div className='flex items-center justify-center border-2 border-dashed h-28 p-2 flex flex-col justify-center  border-orange-500'>
                <input
                    type="file"
                    className='w-full'
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(event) => handleInputChange(event, "documento_retro")}
                />
            </div>
        </div>
    )
}

const CodiceFiscaleInputField = ({ myFile, setMyFile, handleInputChange }) => {
    return (
        <>
            <div className='h-10' />
            <div className='flex flex-col sd:w-3/12 md:2-8/12 lg:w-4/12'>
                <span className={myFile['codicefiscale_fronte'] === null ? `text-red-600` : `text-green-600`}>Codice Fiscale Fronte</span>
                <div className='h-2' />
                <div className='flex items-center justify-center border-2 border-dashed h-28 p-2 flex flex-col justify-center border-orange-500'>
                    <input
                        className='w-full'
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        id="documento-fronte"
                        onChange={(event) => handleInputChange(event, "codicefiscale_fronte")}
                    />
                </div>
                <div className='h-10' />
                <span className={myFile['codicefiscale_retro'] === null ? `text-red-600` : `text-green-600`}>Codice Fiscale Retro</span>
                <div className='h-2' />
                <div className='flex items-center justify-center border-2 border-dashed h-28 p-2 flex flex-col justify-center  border-orange-500'>
                    <input
                        type="file"
                        className='w-full'
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(event) => handleInputChange(event, "codicefiscale_retro")}
                    />
                </div>
            </div>
        </>
    )
}

const SubmitButton = ({ filesToUpload, setErrorMessage, email }) => {
    const navigate = useNavigate();
    const [hover, showHover] = useState(false)

    const handleUpload = async () => {
        const formData = new FormData();
        // I upload the document to the formData that will be later sent as the API's body
        formData.append('doc_fronte', filesToUpload["documento_fronte"]);
        formData.append('doc_retro', filesToUpload["documento_retro"]);
        if (filesToUpload["codicefiscale_fronte"] !== null && filesToUpload["codicefiscale_retro"] !== null) {
            formData.append('cf_fronte', filesToUpload["codicefiscale_fronte"]);
            formData.append('cf_retro', filesToUpload["codicefiscale_retro"]);
        } 
        formData.append('key', "LsHRInkLIUMiNuSPiLTaRanIaLsERderANtiCkEybOviOSaLEytioNtIONATErTiTENatOrtIontIEfEUtWuPREarAmYCiRoamFovYSXyqWWkidJnSwj3qgg9YjE3Q894D3EUZzAFhAs5ark8VQPXEXijYuOEmVQ6wTingvTCzanwZiBXBnY39neg95QRFDrm4rKx0kn");
        formData.append('dati', 4);
        formData.append('email', email.current);
        axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if (response.data["status"] !== "success") {
                    setErrorMessage(`Si è verificato il seguente errore: ${response.data["descrizione"]}. Rieffettuare la procedura con un nuovo link. Se l'errore persiste contattare l'assistenza.`)
                } else {
                    navigate('/terzo-step', {state: {email: email.current}});
                }
            })
    };

    return (
        <>
            <div className='h-12'></div>
            <button className={
                `${hover ? 'bg-orange-500' : 'bg-transparent'} 
                ${hover ? 'text-white' : 'text-orange-500'} 
                border-orange-500 border-2 font-bold w-64 h-16 shadow-lg`}
                onMouseEnter={() => showHover(true)}
                onMouseLeave={() => showHover(false)}
                onClick={

                    () => {
                        if (filesToUpload["documento_fronte"] !== null && filesToUpload["documento_retro"] !== null) {
                            handleUpload();
                        }
                    }
                }
            >
                PROSEGUI
            </button>
            <div className='h-12'></div>
        </>
    )
}
