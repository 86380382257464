import React from 'react'
import ReactPlayer from 'react-player'

import ContrattoPdf from '../assets/contratto.pdf';
import DelegaPdf from '../assets/delega.pdf';
import ProformaPdf from '../assets/proforma.pdf';
import { Link } from "react-router-dom";
import { GeneralButton } from '../components/GeneralButton';

export const Introduction = () => {
    return (
        <div className='flex flex-col items-center'>
            <ReactPlayer url='https://www.youtube-nocookie.com/embed/mkggXE5e2yk?si=GCxC6RExnJfIRIY8' controls={true} width={512} height={288} />
            <div className='h-8' />
            <p className='text-center'>
                Clicca sull'icona, <br /> qui puoi vedere l'anteprima del contratto
            </p>
            <div className='h-8' />
            <PdfRowSection />
            <div className='h-12' />
            <Link to="/istruzioni"> <GeneralButton buttonText={"PROCEDI"} /> </Link>
        </div>
    )
}

const PdfRowSection = () => {
    return (
        <>
            <div className='flex flex-col sm:flex-row'>
                <DownloadPdfComponent pdfName='Contratto' pdf={ContrattoPdf} />
                <div className='h-8 sm:w-4 ' />
                <DownloadPdfComponent pdfName='Delega' pdf={DelegaPdf} />
                <div className='h-8 sm:w-4' />
                <DownloadPdfComponent pdfName='Proforma' pdf={ProformaPdf} />
            </div>
        </>
    )
}

const DownloadPdfComponent = ({ pdfName, pdf }) => {
    return (
        <div className='flex flex-col items-center'>
            <p className='font-semibold'>{pdfName}</p>
            <div className='h-4' />
            <a
                href={pdf}
                download={pdfName}
                target="_blank"
                rel="noreferrer"
            >
                <img src={require('../assets/pdf_icon.png')} alt='pdf-icon' width={100} height={100} />
            </a>
        </div>
    )
}