import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import useRedirectToErrorPage from '../errors/hooks/redirectToErrorPage';

/** 
 * Primo step mandatorio per la firma del contratto.
 * -------
 * Uno degli input è 'Prefisso', è dichiarato siccome i clienti potrebbero essere stranieri e dal momento
 * YouSign utilizza l'OTP tramite messaggio per la firma del
 * contratto è necessario che il numero telefonico sia corretto.
 * UPDATE: Non chiediamo più il prefisso lato client
 * -------
 * Parlando con Mirko abbiamo deciso di non inserire email e telefono, saranno gli operatori ad 
 * assicurarsi che email e telefono siano corretti, questo è stato deciso per le seguenti motivazioni:
 * - L'email è chiave primaria e modificarla nel mezzo della procedura aggiunge carico di lavoro.
 * - Il telefono ha necessità del prefisso per funzionare su YouSign, saranno gli operatori ad accertarsene.
 */

const inputFields = [
    { label: 'Nome', state: 'nome' },
    { label: 'Cognome', state: 'cognome' },
    { label: 'Indirizzo', state: 'indirizzo' },
    { label: 'Città', state: 'citta' },
    { label: 'Provincia', state: 'provincia', maxlength: '2' },
    { label: 'CAP', state: 'cap', maxlength: '5' },
    { label: 'Data di Nascita', state: 'dataNascita', type: 'date' },
    { label: 'Codice Fiscale', state: 'codiceFiscale' },
]

export default function FirstStepWizard() {
    // Hooks declaration
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState();
    const redirectToErrorPage = useRedirectToErrorPage(errorMessage);
    let location = useLocation();
    const clientCode = useOutletContext();

    const [alertState, showAlert] = useState(false);
    const [formState, setFormState] = useState({
        nome: '',
        cognome: '',
        indirizzo: '',
        citta: '',
        provincia: '',
        cap: '',
        dataNascita: '',
        codiceFiscale: ''
    })

    const email = useRef("");



    useEffect(() => {
        window.scrollTo(0, 0);
        
        let body = JSON.stringify(
            {
                "key": process.env.REACT_APP_API_KEY,
                "dati": 2,
                "codice_utente": clientCode
            }
        )
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`, body).then((response) => {
            if (response.data["status"] !== "success") {
                setErrorMessage(`Si è verificato un errore. Rieffettuare la procedura con un nuovo link. Se l'errore persiste contattare l'assistenza.${response.data["descrizione"]}`)
            } else {
                setFormState(prevState => ({
                    ...prevState,
                    nome: response.data["data"]["nome"] !== null ? response.data["data"]["nome"] : '',
                    cognome: response.data["data"]["cognome"] !== null ? response.data["data"]["cognome"] : '',
                    indirizzo: response.data["data"]["indirizzo"] !== null ? response.data["data"]["indirizzo"] : '',
                    citta: response.data["data"]["citta"] !== null ? response.data["data"]["citta"] : '',
                    provincia: response.data["data"]["provincia"] !== null ? response.data["data"]["provincia"] : '',
                    cap: response.data["data"]["cap"] !== null ? response.data["data"]["cap"] : '',
                    dataNascita: response.data["data"]["data_nascita"] !== null && response.data["data"]["data_nascita"] !== '0000-00-00' ? response.data["data"]["data_nascita"] : '',
                    codiceFiscale: response.data["data"]["codice_fiscale"] !== null ? response.data["data"]["codice_fiscale"] : '',
                }))
                email.current = response.data["data"]["email"];
            }
        })
    }, [navigate, redirectToErrorPage, location, clientCode])

    const handleInput = (state, value) => {
        setFormState(prevState => ({
            ...prevState,
            [state]: value
        }));

    }

    return (
        <>
            <div className='flex flex-col items-center'>
                <h1 className='text-xl font-semibold text-orange-500'>Dati Necessari</h1>
                <div className='h-12' />
                <div className={alertState ? 'block' : 'hidden'}>
                    <AlertMissingFields />
                </div>
                <form className='flex flex-col items-center w-10/12 lg:w-4/12 '
                    onSubmit={
                        (e) => {
                            e.preventDefault();
                            if (Object.values(formState).every(value => !isEmpty(value))) {
                                let body = JSON.stringify(
                                    {
                                        "key": process.env.REACT_APP_API_KEY,
                                        "dati": 3,
                                        "email": email.current,
                                        "nome": formState['nome'],
                                        "cognome": formState['cognome'],
                                        "indirizzo": formState['indirizzo'],
                                        "citta": formState['citta'],
                                        "codice_fiscale": formState['codiceFiscale'],
                                        "provincia": formState['provincia'],
                                        "cap": formState['cap'],
                                        "data_nascita": formState['dataNascita'],
                                    }
                                )

                                axios.post(`${process.env.REACT_APP_API_BASE_URL}/contratto_digitale/data.php`, body).then((response) => {
                                    if (response.data["status"] === "success") {
                                        navigate('/istruzioni-secondo-step', { state: { key: { "authorized": true }, email: email.current } });
                                    } else {
                                        setErrorMessage(`Si è verificato un errore. Rieffettuare la procedura con un nuovo link. Se l'errore persiste contattare l'assistenza. ${response.data["descrizione"]}`)
                                    }
                                })
                            }
                            else {
                                showAlert(true)
                            }
                        }
                    }
                >
                    <div className="grid gap-x-4 gap-y-4 lg:gap-y-6 sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-6 ">
                        {inputFields.map(({ label, state, type, span, maxlength, min, max }) => (
                            <div className={`${typeof span !== 'undefined' ? span : `col-span-3`}`}>
                            <p className={`font-semibold ${isEmpty(formState[state], label) ? 'text-red-600' : 'text-green-600'}`}>{label}</p>
                            <input
                                key={label}
                                value={formState[state]}
                                maxLength={`${typeof maxlength !== 'undefined' ? maxlength : '150'}`}
                                onChange={(e) => handleInput(state, e.target.value)}
                                className={
                                    `border-2 p-2 
                                ${isEmpty(formState[state], label) ? 'border-red-600' : 'border-green-600'} 
                                focus:outline-none
                                
                                `
                                }
                                type={typeof type !== 'undefined' ? type : 'text'}
                                placeholder={label}
                                min={typeof min !== 'undefined' ? min : ''}
                                max={typeof max !== 'undefined' ? max : ''}
                            />
                            </div>
                        ))}
                    </div>
                    <SubmitButton />
                </form>
            </div>
        </>
    )
}

const SubmitButton = () => {
    const [hover, showHover] = useState(false)
    return (
        <>
            <div className='h-12'></div>
            <button className={
                `${hover ? 'bg-orange-500' : 'bg-transparent'} 
                ${hover ? 'text-white' : 'text-orange-500'} 
                border-orange-500 border-2 font-bold w-64 h-16 shadow-lg`}
                onMouseEnter={() => showHover(true)}
                onMouseLeave={() => showHover(false)}
                type='submit'
            >
                PROSEGUI
            </button>
            <div className='h-12'></div>
        </>
    )
}

const AlertMissingFields = () => {
    return (
        <>
            <div className="flex flex-col bg-red-100 border border-red-400 text-red-700 px-12 py-3 rounded relative" role="alert">
                <strong className="font-bold">Attenzione!</strong>
                <span className="block sm:inline">Completa tutti i campi prima di continuare.</span>
            </div>
            <div className='h-12'></div>
        </>
    )
}

const isEmpty = (value, label) => {
    return value.trim() === '';
};